import * as React from "react"

import { ContentGrid } from "../components/global"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <ContentGrid>
      <h1>404: Not found</h1>
      <p>The truth is out there... but unfortunately this page isn't.</p>
      <p>Try one of our articles instead:</p>
    </ContentGrid>
  </Layout>
)

export default NotFoundPage
